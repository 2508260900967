import applicationApi from 'api-client/application'
import baseApp from '@/application-base'
import { converToISODate } from '@/utils/date'

const formModule = {
  state: {
    application: JSON.parse(JSON.stringify(baseApp)),
    // flag to identify is user select the resume application button
    isResuming: true,
    applicationId: null,
    // Identifies if declaration has been accepted. We dot not save this
    // in the application as they must accept it again if they come back.
    declarationAccepted: null,
    promptInProgressApplication: false,
    resumeNoApplication: false
  },
  getters: {
    form: (state) => state.application, // TODO: remove and use application
    application: (state) => state.application,
    meta: (state) => state.application.meta,
    consideringOoAGovtSchools: (state) =>
      state.application.consideringOoAGovtSchools,
    consideringSelectiveSchool: (state) =>
      state.application.consideringSelectiveSchool,
    student: (state) => state.application.student,
    parentCarers: (state) => state.application.parentCarers,
    ooaHighSchools: (state) => state.application.ooaHighSchools,
    isResuming: (state) => state.isResuming,
    currentStep: (state) => state.application.meta.currentStep,
    validStudent: (state) => state.application.meta.validStudent,
    validParentCarers: (state) => state.application.meta.validParentCarers,
    validHighSchool: (state) => state.application.meta.validHighSchool,
    localHighSchools: (state) => state.application.meta.localSecondarySchools,
    validSiblings: (state) => state.application.meta.validSiblings,
    validConsiderations: (state) => state.application.meta.validConsiderations,
    applicationId: (state) => state.applicationId,
    declarationAccepted: (state) => state.declarationAccepted,
    // This will need to be updated to be dynamic based on the steps in the form.
    validForm: (state, getters) =>
      getters.validParentCarers &&
      getters.validStudent &&
      getters.validHighSchool &&
      (getters.validConsiderations && getters.validConsiderations !== undefined
        ? Object.values(getters.validConsiderations)?.every?.((x) => x)
        : true),
    promptInProgressApplication: (state) => state.promptInProgressApplication,
    resumeNoApplication: (state) => state.resumeNoApplication
  },
  mutations: {
    setApplication(state, application) {
      state.application = application
    },
    setIsResuming(state, resuming) {
      state.isResuming = resuming
    },
    setCurrentStep(state, step) {
      state.application.meta.currentStep = step
    },
    setStudent(state, student) {
      state.application.student = student
    },
    // Different to setStudent in that it will only update provided variables
    updateStudent(state, updatedStudent) {
      const currStudent = state.application.student || {}
      state.application.student = { ...currStudent, ...updatedStudent }
    },
    updateParentCarer(state, { index, parent }) {
      const currParent = state.application.parentCarers?.[index] || {}
      this._vm.$set(state.application.parentCarers, index, {
        ...currParent,
        ...parent
      })
    },
    setOtherStudentDetails(state, otherDeatils) {
      state.application.otherStudentDetails = otherDeatils
    },
    setConsideringOoAGovtSchools(state, value) {
      state.application.consideringOoAGovtSchools = value
    },
    setConsideringSelectiveSchool(state, value) {
      state.application.consideringSelectiveSchool = value
    },
    setValidStudent(state, isValid) {
      if (isValid === false) {
        state.declarationAccepted = false
      }
      state.application.meta.validStudent = isValid
    },
    setValidParentCarers(state, isValid) {
      if (isValid === false) {
        state.declarationAccepted = false
      }
      state.application.meta.validParentCarers = isValid
    },
    setValidHighSchool(state, isValid) {
      if (isValid === false) {
        state.declarationAccepted = false
      }
      state.application.meta.validHighSchool = isValid
    },
    setValidConsiderations(state, validConsiderations) {
      if (
        Object.keys(validConsiderations).some((isValid) => isValid === false)
      ) {
        state.declarationAccepted = false
      }
      state.application.meta.validConsiderations = validConsiderations
    },
    setValidConsideration(state, { schoolCode, isValid }) {
      const { validConsiderations } = state.application.meta
      if (isValid === false) {
        state.declarationAccepted = false
      }
      state.application.meta.validConsiderations = {
        ...validConsiderations,
        [schoolCode]: isValid
      }
    },
    setParentCarers(state, parentCarers) {
      state.application.parentCarers = parentCarers
    },
    setOoaHighSchools(state, highSchools) {
      this._vm.$set(state.application, 'ooaHighSchools', highSchools)
    },
    setOtherConsiderations(state, { schoolCode, otherConsiderations }) {
      const index = state.application.ooaHighSchools.findIndex(
        (school) => school.schoolCode === schoolCode
      )
      const schoolData = state.application.ooaHighSchools?.[index]
      this._vm.$set(state.application.ooaHighSchools, index, {
        ...schoolData,
        otherConsiderations
      })
    },
    setCriteria(state, { criteria, schoolCode }) {
      const index = state.application.ooaHighSchools.findIndex(
        (school) => school.schoolCode === schoolCode
      )
      const schoolData = state.application.ooaHighSchools?.[index]
      this._vm.$set(state.application.ooaHighSchools, index, {
        ...schoolData,
        criteria
      })
    },
    setSiblings(state, { siblings, schoolCode }) {
      const index = state.application.ooaHighSchools.findIndex(
        (school) => school.schoolCode === schoolCode
      )
      const schoolData = state.application.ooaHighSchools?.[index]
      this._vm.$set(state.application.ooaHighSchools, index, {
        ...schoolData,
        siblings
      })
    },
    setHasAnySiblings(state, { hasAnySiblings, schoolCode }) {
      const index = state.application.ooaHighSchools.findIndex(
        (school) => school.schoolCode === schoolCode
      )
      const schoolData = state.application.ooaHighSchools?.[index]
      this._vm.$set(state.application.ooaHighSchools, index, {
        ...schoolData,
        hasAnySiblings
      })
    },
    setApplicationId(state, id) {
      state.applicationId = id
    },
    setDeclarationAccepted(state, accepted) {
      state.declarationAccepted = accepted
    },
    mergePreAppIntoApp(
      state,
      { residentialAddress, school, localSecondarySchools }
    ) {
      const { schoolCode, schoolName, catchmentLevel } = school
      const application = JSON.parse(JSON.stringify(baseApp))
      application.residentialAddress = residentialAddress
      application.currentPrimarySchool = {
        schoolCode,
        schoolName,
        catchmentLevel
      }
      application.meta.localSecondarySchools = localSecondarySchools
      state.application = application
    },
    setSelectedLocalSecondarySchool(state, value) {
      state.application.localHighSchool = {
        schoolCode: value.school_code,
        schoolName: value.school_name,
        catchmentLevel: value.catchmentLevel
      }
    },
    setPromptInProgressApplication(state, value) {
      state.promptInProgressApplication = value
    },
    setResumeNoApplication(state, value) {
      state.resumeNoApplication = value
    }
  },
  actions: {
    setSelectedLocalSecondarySchool({ commit }, school) {
      commit('setSelectedLocalSecondarySchool', school)
    },
    setCurrentStep({ commit }, currentStep) {
      commit('setCurrentStep', currentStep)
    },
    resetPreviousSchoolId({ commit }, { schoolId }) {
      commit('setPreviousSchoolId', schoolId)
    },
    async beginApplication({ commit, getters, dispatch }) {
      const application = await applicationApi.fetchApplication()
      if (application) {
        // prompt in progress application when creating a new application for the same email address
        if (!getters.isResuming) {
          commit('setPromptInProgressApplication', true)
        } else {
          commit('setPromptInProgressApplication', false)
          const school = application?.currentPrimarySchool
          sessionStorage.setItem('schoolCode', school?.schoolCode)
          commit('setSchool', school)
          // If there is an application then load it and proceed
          this._vm.$log.debug('application found, resuming')
          // update meta for ooaHighSchools
          if (application.ooaHighSchools) {
            application.ooaHighSchools.forEach((school) => {
              if (
                application.meta.validConsiderations[school.schoolCode] ===
                undefined
              ) {
                const schoolCodeKey = school.schoolCode
                Object.assign(application.meta.validConsiderations, {
                  [schoolCodeKey]: undefined
                })
              }
            })
          }
        }
        commit('setApplication', application)
      } else if (getters.isResuming) {
        // Else there is no application but the user is resuming => error
        this._vm.$log.debug('no application found, but is resuming => error')
        commit('setResumeNoApplication', true)
      } else {
        // Else user must be starting a new application
        this._vm.$log.debug('no application found, creating')
        await dispatch('createApplication')
      }
      await dispatch('getCriteriaForOOASchools')
      dispatch('updateCapacityForOOASchools')
      return true
    },
    getCriteriaForOOASchools({ getters, dispatch }) {
      const criteriaPromises = getters.ooaHighSchools?.map((school) =>
        school.y67t
          ? dispatch('getSchoolCustomisation', school.schoolCode)
          : null
      )
      return criteriaPromises ? Promise.all(criteriaPromises) : null
    },
    updateCapacityForOOASchools({ rootState, getters, commit }) {
      const { pilotSchools } = getters
      const modifiedCapacityData = getters.ooaHighSchools?.map((school) => {
        const item = school
        const pilotSchool = pilotSchools?.find(
          (element) => element.schoolCode === school.schoolCode
        )
        item.capacity = pilotSchool?.capStatus
        const schoolCustomisationData = rootState.school.schoolCustomisations
          ? rootState.school.schoolCustomisations[school.schoolCode]
          : null
        if (schoolCustomisationData) {
          const hasCapChanged =
            item.capacity === schoolCustomisationData.ooa.capacity.code
          if (!hasCapChanged) {
            item.capacity = schoolCustomisationData.ooa.capacity.code
            if (schoolCustomisationData.ooa.capacity.code === 'Over') {
              item.criteria = null
            } else {
              // clear otherConsiderations if cap changed from Over to Under/Approach
              item.otherConsiderations = item.y67t
                ? null
                : item.otherConsiderations
              // preselect siblings checkbox if cap changed from Over to Under/Approach and
              // sibling was selected before changing cap
              const hasSibCriteria =
                item.hasAnySiblings &&
                item.criteria?.find((item) => item.code === 'SIB')

              if (hasSibCriteria === undefined) {
                Array.isArray(item.criteria)
                  ? item.criteria.push({ code: 'SIB' })
                  : (item.criteria = [{ code: 'SIB' }])
              }
              if (item.hasAnySiblings === false) {
                item.hasAnySiblings = null
              }
            }
          }
        }
        return item
      })
      commit('setOoaHighSchools', modifiedCapacityData)
    },
    // create a new application in dynamoDB.
    // This should only be invoked when creating the application for the first time.
    async createApplication({ commit, getters }) {
      const { residentialAddress, school, localSecondarySchools } = getters
      commit('mergePreAppIntoApp', {
        residentialAddress,
        school,
        localSecondarySchools
      })
      commit('resetPreApplication')
      return applicationApi.createApplication(getters.application)
    },
    saveApplication({ state }) {
      return applicationApi.saveApplication(state.application)
    },
    submitApplication({ state, getters }) {
      // Convert/add some variables in application to correct format for submission
      const { application } = state
      application.student.dateOfBirth = converToISODate(
        application.student.dateOfBirth
      )
      application.ooaHighSchools = application.ooaHighSchools?.map(
        // eslint-disable-next-line no-unused-vars
        ({ website, ...school }) => {
          const siblings = school.siblings?.map((sibling) => ({
            ...sibling,
            siblingsDOB: converToISODate(sibling.siblingsDOB)
          }))
          // Only submit active criteria data
          // (i.e. if the selection criteria has been deactivated, don't submit the data)
          // eslint-disable-next-line max-len
          const activeCriteria = getters
            .selectionCriteria(school.schoolCode)
            ?.filter?.(({ active }) => active)
          const criteria = school.criteria?.reduce(
            (accumulator, criterion) =>
              activeCriteria?.some(
                (activeCriterion) => activeCriterion.code === criterion.code
              )
                ? [...accumulator, criterion]
                : accumulator,
            []
          )
          return { ...school, siblings, criteria }
        }
      )
      application.applicationStatus = 'New'
      delete application.meta
      application.nonGovtHighSchool = ''
      application.localHighSchool.schoolCode = String(
        application.localHighSchool.schoolCode
      )

      return applicationApi.submitApplication(state.application)
    },
    resetApplication({ commit }) {
      commit('setApplication', JSON.parse(JSON.stringify(baseApp)))
    }
  }
}

export default formModule
