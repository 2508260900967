import axios from 'axios'
import store from '@/store'

const API_REFDATA = process.env.VUE_APP_API_REFDATA

const getPilotSchools = () => {
  const url = `${API_REFDATA}/oes-pilot-schools.json`

  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => {
      // set error in store & throw exceptions so that it can be propagated to view for handling.
      const error = new Error(
        'Failed to load reference data (oes-pilot-schools.json)'
      )
      store.commit('setError', error)
      throw error
    })
}

const getSchoolCustomisation = (schoolCode) => {
  const url = `${API_REFDATA}/webCustomisation/${schoolCode}-web-customisation.json`

  return axios
    .get(url, { ignoreErrorHandling: true })
    .then((res) => res.data)
    .catch(() => null)
}
const getSchoolsForAddress = (url, long, lat) => {
  const sqlQuery = `SELECT s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.school_subtype,
                           s.postcode, s.longitude, s.latitude, phone, website, c.calendar_year,
                           (EXTRACT(YEAR FROM now()) + 1) as next_year,
                           ARRAY(SELECT sc.calendar_year
                                 FROM   catchments_2020 sc
                                 WHERE  s.school_code = sc.school_code) as all_calendar_years
                    FROM   dec_schools_2020 s
                    JOIN   catchments_2020 c ON s.school_code = c.school_code
                    WHERE  c.catchment_level = 'secondary'
                    AND    ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long},${lat}), 4326));`

  return axios.get(`${url}?q=${encodeURIComponent(sqlQuery)}`)
}

export default {
  getPilotSchools,
  getSchoolsForAddress,
  getSchoolCustomisation
}
