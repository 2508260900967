import refDataApi from 'api-client/ReferenceData'
import {
  checkIsBlockEoiProceed,
  checkIsBlockEoiProceedClosed
} from '@/utils/date'

const refDataModule = {
  state: {
    states: null,
    allStates: null,
    residencyStatuses: null,
    relationships: null,
    titles: null,
    contactTypes: null,
    predictiveSchools: null,
    highSchools: null,
    oesProperties: null,
    customisationDefinitions: null,
    isBlockEoiProceed: false,
    isBlockEoiProceedClosed: false
  },
  getters: {
    states: (state) => state.states,
    allStates: (state) => state.allStates,
    residencyStatuses: (state) => state.residencyStatuses,
    relationships: (state) => state.relationships,
    titles: (state) => state.titles,
    contactTypes: (state) => state.contactTypes,
    predictiveSchools: (state) => state.predictiveSchools,
    highSchools: (state) => state.highSchools,
    oesProperties: (state) => state.oesProperties,
    additionalSchoolMessages: (state) =>
      state.oesProperties?.y67tAdditionalSchoolMessages,
    privacyLinks: (state) => state.oesProperties?.urls?.privacy,
    commonCriteriaLink: (state) => state.oesProperties?.urls?.commonCriteria,
    customisationDefinitions: (state) => state.customisationDefinitions,
    isBlockEoiProceed: (state) => state.isBlockEoiProceed,
    isBlockEoiProceedClosed: (state) => state.isBlockEoiProceedClosed
  },
  mutations: {
    setStates(state, states) {
      state.states = states
    },
    setAllStates(state, allStates) {
      state.allStates = allStates
    },
    setResidencyStatuses(state, residencyStatuses) {
      state.residencyStatuses = residencyStatuses
    },
    setRelationships(state, relationships) {
      state.relationships = relationships
    },
    setTitles(state, titles) {
      state.titles = titles
    },
    setContactTypes(state, contactTypes) {
      state.contactTypes = contactTypes
    },
    setPredictiveSchools(state, schools) {
      state.predictiveSchools = schools
    },
    setHighSchools(state, schools) {
      state.highSchools = schools
    },
    setOESProperties(state, properties) {
      state.oesProperties = properties
    },
    setCustomisationDefinitions(state, customisations) {
      state.customisationDefinitions = customisations
    },
    setIsBlockEoiProceed(state, isBlockEoiProceed) {
      state.isBlockEoiProceed = isBlockEoiProceed
    },
    setIsBlockEoiProceedClosed(state, isBlockEoiProceedClosed) {
      state.isBlockEoiProceedClosed = isBlockEoiProceedClosed
    }
  },
  actions: {
    async getReferenceData({ commit }) {
      await refDataApi.getReferenceData().then((resp) => {
        if (resp) {
          let states = resp.find((item) => item.name === 'States').data
          // Remap from retrieved API key/vals to useable key/vals
          states = states.map((child) => ({
            text: child.value,
            value: child.code
          }))
          commit('setStates', states)

          const labels = {
            nswNonGovtSchool: 'NSW non-government school',
            overseasSchool: 'Overseas school',
            other: 'Other'
          }

          let allStates = resp.find((item) => item.name === 'All_States').data
          // Remap from retrieved API key/vals to useable key/vals
          allStates = allStates
            .filter(
              (child) =>
                child.code !== 'OTH' &&
                child.code !== 'XXX' &&
                child.code !== 'ZZZ'
            )
            .map((child) => {
              const newPropsObj = {
                text: child.value,
                value: child.code
              }

              if (child.value === 'New South Wales') {
                newPropsObj.text = labels.nswNonGovtSchool
              } else if (child.value === 'Overseas') {
                newPropsObj.text = labels.overseasSchool
              } else if (child.value === 'Other/Not Applicable') {
                newPropsObj.text = labels.other
              }

              return newPropsObj
            })
          commit('setAllStates', allStates)

          let residencyStatuses = resp.find(
            (item) => item.name === 'ResidencyStatus'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          residencyStatuses = residencyStatuses.map((child) => ({
            text: child.value,
            value: child.code
          }))
          commit('setResidencyStatuses', residencyStatuses)

          let relationships = resp.find(
            (item) => item.name === 'Relationship'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          relationships = relationships
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setRelationships', relationships)

          let titles = resp.find((item) => item.name === 'Title').data
          // Remap from retrieved API key/vals to useable key/vals
          titles = titles
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setTitles', titles)

          let contactTypes = resp.find(
            (item) => item.name === 'ContactType'
          ).data
          // Remap from retrieved API key/vals to useable key/vals
          contactTypes = contactTypes
            .filter((child) => child.active === 'A')
            .map((child) => ({
              text: child.value,
              value: child.code
            }))
          commit('setContactTypes', contactTypes)
        }
      })
    },
    async initReferenceData({ dispatch, state }) {
      const refData =
        state.states === null ||
        state.residencyStatuses === null ||
        state.relationships === null ||
        state.titles === null ||
        state.contactTypes === null ||
        state.allStates === null
      await Promise.all([
        refData ? dispatch('getReferenceData') : Promise.resolve()
      ])
    },
    async getPredictiveSchools({ commit }) {
      await refDataApi.getPredictiveSchools().then((resp) => {
        commit('setPredictiveSchools', resp)
      })
    },
    async getHighSchools({ commit }) {
      await refDataApi.getHighSchools().then((resp) => {
        commit('setHighSchools', resp)
      })
    },
    async getOESProperties({ commit }) {
      await refDataApi.getOESProperties().then((resp) => {
        commit('setOESProperties', resp)
        const isBlockEoiProceed = checkIsBlockEoiProceed(resp?.y67application)
        commit('setIsBlockEoiProceed', isBlockEoiProceed)
        commit(
          'setIsBlockEoiProceedClosed',
          checkIsBlockEoiProceedClosed(
            isBlockEoiProceed,
            resp?.y67application?.blockStartDate
          )
        )
      })
    },
    async getCustomisationDefinitions({ commit }) {
      const definitions = await refDataApi.getCustomisationDefinitions()
      commit('setCustomisationDefinitions', definitions)
      return definitions
    }
  }
}

export default refDataModule
