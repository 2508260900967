<template>
  <v-form class="email-otp pa-5 white-card" @submit.prevent="handleSubmit">
    <v-row>
      <v-col>
        <slot name="text" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          ref="otp"
          v-model="internalOtpCode"
          :error-messages="internalErrorMessages"
          v-bind="textAttributes"
          v-on="$listeners"
          persistent-placeholder
        >
          <!-- Passes all slots/scopedSlots passed in to this component down into v-text-field -->
          <template v-for="(_, slot) of scopedSlots" #[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
          <template v-for="(_, name) in $slots" #[name]>
            <slot :name="name" />
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <AdsButton
          class="primary email-otp__button"
          :disabled="disabled"
          v-bind="buttonAttributes"
          :button-text="buttonText"
          :icon="buttonIcon"
          :loading="loading"
          @click="handleSubmit"
        >
          <template #loader>
            <v-progress-circular
              class="icon spin button__spinner"
              :size="20"
              :width="4"
              color="white"
              indeterminate
              aria-label="loading"
            />
            {{ buttonText }}
          </template>
        </AdsButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import authApi from 'api-client/auth'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { API_ERROR_SUBTYPES } from '@/constants'

const EVENTS = {
  UPDATE_ERROR_MESSAGES: 'update:errorMessages',
  UPDATE_OTP_CODE: 'update:otpCode',
  UPDATE_LOADING: 'update:loading',
  UPDATE_COGNITO_SESSION: 'update:cognitoSession',
  UPDATE_ATTEMPTS_REMAINING: 'update:attemptsRemaining',
  AUTHENTICATED: 'authenticated'
}

export default {
  name: 'OesLoginOTP',
  components: {
    AdsButton
  },
  inheritAttrs: false,
  props: {
    otpCode: {
      type: String
    },
    email: {
      type: String
    },
    attemptsRemaining: {
      type: Number,
      default: 3
    },
    cognitoSession: {
      type: String
    },
    buttonIcon: {
      type: String,
      default: 'mdi-arrow-right'
    },
    buttonAttrs: {
      type: Object,
      default: () => {}
    },
    buttonText: {
      type: String,
      default: 'Verify'
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    otpLength: {
      type: Number,
      default: 6
    },
    authenticateApiUrl: {
      type: String
      // required: true <-- Add in common-ui version
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      invalidOTPCode: '',
      dataErrorMessages: this.errorMessages,
      dataAttemptsRemaining: this.attemptsRemaining,
      dataOtpCode: this.otpCode,
      dataCognitoSession: this.cognitoSession
    }
  },
  computed: {
    internalOtpCode: {
      get() {
        return this.dataOtpCode
      },
      set(value) {
        this.dataOtpCode = value
        this.$emit(EVENTS.UPDATE_OTP_CODE, value)
      }
    },
    internalAttemptsRemaining: {
      get() {
        return this.dataAttemptsRemaining
      },
      set(value) {
        this.dataAttemptsRemaining = value
        this.$emit(EVENTS.UPDATE_ATTEMPTS_REMAINING, value)
      }
    },
    internalErrorMessages: {
      get() {
        return this.dataErrorMessages
      },
      set(value) {
        this.dataErrorMessages = value
        this.$emit(EVENTS.UPDATE_ERROR_MESSAGES, value)
      }
    },
    internalCognitoSession: {
      get() {
        return this.dataCognitoSession
      },
      set(value) {
        this.dataCognitoSession = value
        this.$emit(EVENTS.UPDATE_COGNITO_SESSION, value)
      }
    },
    textAttributes() {
      const defaultAttrs = {
        label: 'Verification code',
        placeholder: 'Enter your 6 digit code',
        outlined: true,
        maxlength: 6,
        required: true
      }
      return { ...defaultAttrs, ...this.$attrs }
    },
    buttonAttributes() {
      const defaultAttrs = {
        'x-large': true,
        color: 'primary'
      }
      return { ...defaultAttrs, ...this.buttonAttrs }
    },
    scopedSlots() {
      // eslint-disable-next-line no-unused-vars
      const { text, ...rest } = this.$scopedSlots
      return rest
    },
    valid() {
      return !(this.internalErrorMessages?.length > 0)
    },
    disabled() {
      return (
        this.internalOtpCode === undefined ||
        this.internalOtpCode?.length < 6 ||
        this.internalAttemptsRemaining === 0
      )
    }
  },
  watch: {
    errorMessages(value) {
      this.dataErrorMessages = value
    },
    otpCode(value) {
      this.dataOtpCode = value
    },
    attemptsRemaining(value) {
      this.dataAttemptsRemaining = value
    },
    cognitoSession(value) {
      this.dataCognitoSession = value
    },
    dataOtpCode(newValue, oldValue) {
      // Reset error flag when user changes OTP text and has attempts remaining
      if (
        newValue !== oldValue &&
        this.internalErrorMessages !== [] &&
        this.internalAttemptsRemaining > 0
      ) {
        this.internalErrorMessages = []
      }
    }
  },
  methods: {
    validate() {
      if (this.internalOtpCode?.length !== this.otpLength) {
        this.internalErrorMessages = [
          ...this.internalErrorMessages,
          'Please provide a 6 digit verification code.'
        ]
      }
    },
    async handleSubmit() {
      this.validate()
      if (this.valid && this.internalAttemptsRemaining > 0) {
        const tokens = await this.getAuthTokens()
        if (tokens) {
          this.$emit(EVENTS.AUTHENTICATED, tokens)
          // Google Analytics
          this.$gtm.trackEvent({
            event: 'interaction',
            category: 'Authentication',
            action: 'Success',
            label: 'Email'
          })
        }
      }
    },
    async getAuthTokens() {
      this.$emit(EVENTS.UPDATE_LOADING, true)
      try {
        const response = await authApi.authenticate(
          this.authenticateApiUrl,
          this.internalOtpCode,
          this.internalCognitoSession,
          this.email
        )
        // Response is a 200 even on failure, so we must identify
        // a failure by the presence of attemptsLeft key
        if (response.attemptsLeft) {
          this.internalAttemptsRemaining = parseInt(response.attemptsLeft, 10)
          this.internalCognitoSession = response.cognitoSession
          this.internalErrorMessages = [
            ...this.internalErrorMessages,
            'The entered code is incorrect. Please check the code and try again.'
          ]
          return null
        }
        return response
      } catch (error) {
        this.internalAttemptsRemaining = 0
        this.internalErrorMessages = [
          ...this.internalErrorMessages,
          'The code has expired. Please re-enter your email address and try again.'
        ]
        if (error && error.response.status !== 400) {
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.authenticate
          })
          this.$router.push({ name: 'error' })
        }
        return null
      } finally {
        this.$emit(EVENTS.UPDATE_LOADING, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-otp__button {
  width: 100%;
  height: 56px !important;
}
.button__spinner {
  margin-right: 0.5rem;
}
.button__icon {
  margin-right: 0.25rem;
}
.email-otp {
  color: $color-text-body;
}
</style>
